@import "stylesheets/globals/colors";
@import "stylesheets/mixins/responsive";

.rk-account-content {
  margin-top: 100px;

  @include md-only {
    margin-top: 50px;
  }

  @include sm-down {
    margin-top: 25px;
    margin-left: 15px;
  }

  h1 {
    font: 600 30px/35px "SFProDisplay", sans-serif;
    line-height: 1.3;
    //margin-bottom: 50px;
  }

  hr {
    margin: 25px 0 10px 0;
  }

  .rk-title {
    width: 100%;

    hr {
      margin: 0 0 10px 0;
    }

    a {
      position: absolute;
      bottom: 15px;
      right: 0;
    }

    .rk-title-content {
      align-items: center;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: end;

      @include sm-down {
        justify-content: start;
      }
    }
  }

  .rk-account-details {
    width: 100%;
    //padding: 25px 0 0 0;
  }

  .rk-details-list {
    padding-top: 14px;
  }

  .anticon {
    color: $orange;
  }

  .rk-settings-h3 {
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
  }

  .rk-settings-edit {
    display: flex;
    font-size: 20px;
    font-weight: 400;
    color: $blue;
    text-decoration: underline;
    font-style: italic;
    text-align: end;
    cursor: pointer;
    justify-content: end;

    a {
      color: $blue;
    }

    @include sm-down {
      margin-right: 15px;
      font-size: 16px;
    }
  }

  .rk-settings-content {
    font-weight: 400;
  }

  p {
    margin-bottom: 7px;

    @include sm-down {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 4px;
    }
  }

  h2 {
    @include sm-down {
      font-size: 23px;
    }
  }

  .rk-account-btn-text {
    font-weight: 500;
    color: $black;
    text-decoration-line: underline;
    cursor: pointer;

    &:not(:disabled):hover {
      text-decoration-color: $orange;
      text-decoration-line: underline;
    }

    a {
      color: $black;
      border-bottom: none;
      text-decoration: underline;

      &:not(:disabled):hover {
        text-decoration-color: $orange;
        text-decoration-line: underline;
      }
    }
  }

  .rk-settings-space-end {
    width: 100%;
  }
}
