@import "stylesheets/globals/colors";

.rk-switch-ternary {
  background: $admin-grey-dark;

  input {
    position: absolute;
    opacity: 0;
  }

  input + label {
    width: 40px;
    height: 40px;
    float: left;
    cursor: pointer;
    color: $orange;
    background-color: $grey-light;
    outline-color: $grey-light;
    outline-style: none;
  }

  input:checked + label {
    color: $grey-light;
    background-color: $orange;
    outline-color: $orange;
  }

  .anticon {
    margin: 10px;
    font-size: 20px;
  }
}
