@import "stylesheets/mixins/breakpoints";
@import "stylesheets/mixins/responsive";

.story-title-image {
  position: absolute;
  bottom: 100%;
  font-weight: bold;
  font-family: Morion, serif;
  font-size: 35px;
  line-height: 40px;

  @media (max-width: #{$screen-md-max}) or (max-height: 659px) {
    font-size: 30px;
    line-height: 35px;
  }

  @media (max-width: #{$screen-sm-max}) or (max-height: 534px) {
    font-size: 25px;
    line-height: 30px;
  }

  @media (max-width: #{$screen-xs-max}) or (max-height: 424px) {
    font-size: 20px;
    line-height: 25px;
  }
}

.story-title-image--left {
  .story-title-image--container,
  .story-title-image--img {
    height: 150px;
    width: 280px;

    @include xl-only {
      height: 75px;
      width: 250px;
    }

    @include lg-down {
      height: 75px;
      width: 220px;
    }

    @include sm-only {
      height: 75px;
      width: 180px;
    }

    @include xs-down {
      height: 50px;
      width: 100px;
    }
  }
}

.story-title-image--center {
  width: 100%;
  height: 80px;
  position: relative;

  .story-title-image--container {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;

    .story-title-image--img {
      max-height: 150px;
      max-width: 100%;
      margin: auto;
    }
  }
}

.story-title-image--img {
  object-fit: contain;
  object-position: bottom left;
}
