.rk-edit-rich-text-in-place {
  max-width: 100%;
  overflow-x: hidden;
  white-space: break-spaces;

  &.editing {
    min-height: 100%;
    overflow: inherit;
  }
}
