@import "stylesheets/globals/colors";

.rk-credit-card-content {
  margin-top: 150px;

  .rk-credit-card-form {
    input {
      font-size: 20px;
      min-width: 100%;
    }
  }

  .rk-input {
    &.ant-input-affix-wrapper {
      background: white;
    }
  }
}

.rk-payment--subtitle {
  font-size: 16px;
}

.rk-payment--subsubtitle {
  font-size: 13px;
  font-weight: bold;
}

.rk-payment--details {
  width: 100%;
  margin: 16px 0;
  padding: 14px;
  border: 2px solid black;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-column-gap: 15px;

  .rk-payment-details__edit-column {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    a {
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  &.full-width {
    grid-template-columns: 1fr;
  }

  h3 {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 10px;
  }

  .old-price {
    text-decoration: line-through;
  }

  .new-price {
    color: $orange;
  }
}
