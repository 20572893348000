@import "stylesheets/globals/colors";

.rk-coupon-form {
  margin-top: 20px;
  grid-column: 1 / 3;

  .coupon {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;

    .coupon__cancel {
      align-items: flex-end;
      text-align: right;
    }
  }

  .coupon_form {
    .ant-btn {
      min-width: 120px;
      padding: 10px;
      margin-left: auto;
    }

    .coupon__code-input {
      margin-top: 10px;
      margin-bottom: 10px;

      .error-message {
        color: $red;
      }
    }

  }

  .coupon__description {
    color: $orange;
  }
}
