input, textarea {
  -webkit-appearance: none !important;
  -webkit-border-radius: 0 !important;

  &.rk-input, &.ant-input {
    font-family: "SFProDisplay", sans-serif;
    font-weight: 300;
    color: $black;
    background-color: $grey-light;
    padding: 10px;
    border: 1px $admin-grey-dark solid;
    border-radius: 0;
    --antd-wave-shadow-color: none;

    &:active,
    &:focus,
    &:hover {
      border: 1px $orange solid;
    }

    &:active,
    &:focus {
      box-shadow: 0 0 0 0;
      background-color: white !important;
    }
  }
}

.ant-input-affix-wrapper {
  font-family: "SFProDisplay", sans-serif;
  font-weight: 300;
  color: $black;
  background-color: $grey-light;
  padding: 10px;
  border: 1px $admin-grey-dark solid;
  border-radius: 0;
  --antd-wave-shadow-color: none;

  &.ant-input-affix-wrapper-focused,
  &:active,
  &:focus,
  &:hover {
    border: 1px $orange solid;
  }

  &.ant-input-affix-wrapper-focused,
  &:active,
  &:focus {
    box-shadow: 0 0 0 0;
    background-color: white !important;

    input, textarea {
      background-color: white !important;
    }
  }

  input, textarea {
    border: none;

    &:active,
    &:focus,
    &:hover {
      border: none;
    }
  }
}

.rk-switch {
  &.ant-switch-checked {
    background: $orange;

    &:focus {
      box-shadow: 0 0 0 2px $orange-low-sat;
    }
  }

  &.white {
    background: $white-low-sat;

    .ant-switch-handle::before {
      background: $black;
    }

    &.ant-switch-checked {
      background: $white;

      &:hover {
        background: $white;
      }
    }

    &:focus {
      box-shadow: 0 0 0 2px transparent;
    }

    &:hover {
      background: $white-low-sat;
    }
  }

  &.black {
    &.ant-switch-checked {
      background: $black;

      &:hover {
        background: $black;
      }
    }

    &:focus {
      box-shadow: 0 0 0 2px transparent;
    }

    &:hover {
      background: $black-low-sat;
    }
  }
}

.ant-checkbox {
  .ant-checkbox-inner {
    border-radius: 0;
  }
}
