@import "stylesheets/globals/colors";
@import "stylesheets/mixins/responsive";

.rk-home-layout {
  .rk-home--catalogue {
    margin-bottom: 10vh;
  }

  .rk-section {
    padding: 100px 0;
  }

  hr {
    margin: 100px 0;
  }

  .rk-home--download {
    margin-top: 100px;

    .homepage__info-app-badge {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      @include sm-down {
        margin: 0 15px 0 0;
      }

      @include xs-down {
        flex-direction: column;
      }

      svg {
        margin-top: 3px;
        width: 270px;
      }

      img {
        width: 305px;
      }

      @include xs-down {
        justify-content: space-evenly;

        svg {
          margin-top: 3px;
          width: 170px;
        }

        img {
          width: 215px;
        }
      }
    }

    .rk-home--download-content {
      @include md-down {
        padding-left: 15px;
      }

      @include sm-down {
        text-align: center;
      }

      h2 {
        font-size: 30px;
        line-height: 35px;
      }
    }
  }
}

.rk-home--banner {
  margin-top: 5px;
  margin-bottom: 34px;

  .rk-home--banner-img {
    box-shadow: 5px 5px 10px #00000029;

    &.portrait {
      display: none;
    }

    &.landscape {
      display: block;
    }
  }

  @include sm-down {
    min-height: 70vh;
    min-width: 100%;
    overflow: hidden;
    max-width: 100%;

    .rk-home--banner__col {
      min-width: 100%;
      overflow: hidden;
      max-width: 100%;
    }

    .rk-home--banner-img {
      height: 70vh;
      width: 100%;
      object-fit: cover;

      &.portrait {
        display: block;
      }

      &.landscape {
        display: none;
      }
    }
  }
}

.rk-home--banner-text {
  position: absolute;
  left: 50px;
  bottom: 50px;
  text-align: left;

  @include sm-down {
    left: 15px;
    bottom: 15px;
  }
}

.rk-home--banner-button {
  width: 100%;
  font-size: 20px;
  line-height: 25px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: $white;
  background-color: $black;
  border-radius: 20px;
  border-width: 2px;
  padding: 15px 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rk-home--banner-button {
  cursor: pointer;

  &:not(:disabled):hover {
    color: $black;
    background-color: $white;
    border-color: $black;
    border-width: 2px;
  }
}
