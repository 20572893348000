@import "stylesheets/mixins/responsive";

.story-view-button {
  display: flex;
  width: 100%;
  max-width: 300px;
  height: 54px;
  margin: 0;
  border-radius: 10px;
  font-size: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;

  @include sm-down {
    padding: 0 50px
  }
}

.webgl-overlay--store-link {
  img,
  svg {
    max-width: 150px;
  }
}

.story-view-button {
  .story-view-button--icon,
  .rk-spinner {
    height: 20px;
    width: 20px;
  }

  .story-view-button--icon {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 4px;
    padding: 0;
  }
}

.story-view-button--text {
  margin: auto 4px;
  padding: 0;
  font-size: 16px;
  text-align: center;
}

.story-view-button--blue {
  background-color: rgb(28, 32, 57);
  border: 2px solid rgb(28, 32, 57);
  color: rgb(255, 255, 255);
}

.story-view-button--white {
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(28, 32, 57);
  color: rgb(28, 32, 57);
}

.story-view-button--inactive {
  background-color: rgb(242, 242, 242);
  border: 2px solid rgb(97, 99, 113);
  color: rgb(97, 99, 113);
}
