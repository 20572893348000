@import "stylesheets/globals/colors";
@import "stylesheets/mixins/responsive";

.story-view--webgl-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.webgl-overlay--image-landscape {
  position: absolute;
  z-index: 5;
  object-fit: cover;
}

.webgl-overlay--image-portrait-wrapper,
.webgl-overlay--portrait-container,
.webgl-overlay--image-text-portrait,
.webgl-overlay--image-portrait {
  @include sm-down {
    width: 100vw;
    height: calc(100vw * 1.5);
  }
}

.webgl-overlay--image-portrait-wrapper {
  margin-bottom: 300px;

}

.webgl-overlay--image-portrait {
  position: absolute;
  z-index: 5;
  object-fit: cover;
}

.webgl-overlay--image-text {
  position: absolute;
  overflow: hidden;
  display: flex;
  z-index: 6;
  top: 0;
  left: 0;
}

.webgl-overlay--portrait-container {
  .webgl-overlay--image-text {
    margin: 0;
    top: unset;
    bottom: -40px;
    left: 0;
    right: 0;
    min-width: 100%;
    overflow: visible;
  }
}

.webgl-overlay--image-text-portrait {
  position: absolute;
  display: block;
  z-index: 6;
  left: 0;
  bottom: 0;
  min-width: 100%;
  overflow: visible;

  .webgl-overlay--image-text-bottom {
    bottom: -250px;
  }
}

.webgl-overlay--image-text-top-right {
  position: absolute;
  top: 30px;
  right: 50px;

  @include md-down {
    top: 30px;
    right: 40px;
  }

  @include sm-down {
    top: 30px;
    right: 25px;

    svg {
      width: 70px;
    }
  }

  @include xs-down {
    top: 15px;
    right: 15px;
  }
}

.webgl-overlay__gutters {
  grid-row-gap: 10px;
  grid-column-gap: 10px;
}

.webgl-overlay__blur {
  position: absolute;
  pointer-events: none;
  top: -60px;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, black 30%, black 100%);
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(30%, black), color-stop(100%, black));
}

.webgl-overlay--image-text-bottom {
  position: absolute;
  padding-bottom: 25px;
  padding-top: 25px;
  padding-left: 50px;
  padding-right: 50px;
  left: 0;
  right: 0;
  bottom: 0;

  @include md-down {
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @include sm-down {
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.webgl-overlay--blur-portrait {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  height: 250px;
  background-position: bottom;
  background-size: cover;
}

.webgl-overlay--image-text-description-section {
    .biography {
      &:not(.mobile) {
        @include sm-down {
          display: none;
        }
      }
    }

  p {
    font-size: 16px;
    font-weight: 400;
    font-family: SFProDisplay, sans-serif;
  }
}

.webgl-overlay--credits-section {
  margin: 0;
  padding: 0;
  flex-direction: column;
}

.webgl-overlay--first-credit {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  color: rgb(28, 32, 57);
  flex-wrap: wrap;
  white-space: normal;

  strong {
    font-weight: 700;
  }
}

.webgl-overlay--more-credits-button {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  color: rgb(28, 32, 57);
  flex-wrap: wrap;
  white-space: normal;
  background-color: rgba(0, 0, 0, 0);
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  cursor: pointer;
}

.rk-webgl-app {
  position: absolute;
  margin-bottom: 50px;
}

.story-view--webgl-wrapper,
.rk-webgl-app,
.rk-webgl-app--desktop,
.rk-webgl-app--container,
.rk-webgl-app--unity,
.rk-webgl-app--button-container,
.webgl-overlay--container,
.webgl-overlay--container .webgl-overlay--image-landscape,
.webgl-overlay--image-text,
.rk-loading-overlay {
  width: min(calc(100vw - 15px), calc(calc(100vh - 110px) / 0.5625));
}

.story-view--webgl-wrapper,
.rk-webgl-app,
.rk-webgl-app--desktop,
.webgl-overlay--container,
.webgl-overlay--container .webgl-overlay--image-landscape,
.webgl-overlay--image-text,
.rk-loading-overlay {
  margin: auto;
  height: calc(min(calc(100vh - 110px), calc(calc(100vw - 15px) * 0.5625)) + 50px);
}

.webgl-overlay--container,
.webgl-overlay--portrait-container {
  position: relative;

  .ant-image {
    height: 100%;
    width: 100%;
  }
}

.rk-webgl-app--container,
.rk-webgl-app--unity {
  margin: auto;
  height: min(calc(100vh - 110px), calc(calc(100vw - 15px) * 0.5625));

  &.rk-fullscreen {
    width: min(100vw, calc(calc(100vh - 110px) / 0.5625));
  }
}

.rk-fullscreen {
  .rk-webgl-app--unity {
    width: min(100vw, calc(calc(100vh - 110px) / 0.5625));
  }
}

.rk-webgl-app--desktop {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background: $grey-light;

  .rk-webgl-app--container {
    overflow: hidden;
    // We want this to be actually black.
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .rk-webgl-app--button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 0 5px;
    background: $grey-light;
    box-shadow: $black-box-shadow 0 5px 20px 0px;
    text-transform: uppercase;

    .rk-webgl-app--button-container__end {
      display: flex;
      align-items: center;
    }

    .rk-webgl-app--button-container__content {
      display: grid;
      color: $black;
      grid-template-columns: 1fr 1fr auto;
      grid-column-gap: 30px;
      margin-right: auto;

      .story-view--toggle-container .rk-switch {
        margin: 0 0 0 5px;
      }

      .rk-fullscreen-button {
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .rk-fullscreen-button {
      float: right;
      cursor: pointer;
      color: $black;
      padding: 10px;

      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -webkit-transition: -webkit-transform .15s ease-in-out;
      transition: -webkit-transform .15s ease-in-out;
      transition: transform .15s ease-in-out;
      transition: transform .15s ease-in-out, -webkit-transform .15s ease-in-out;
      will-change: transform;

      &:hover {
        transform: scale(1.25);
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.rk-webgl-app--mobile {
  .rk-btn {
    border: 2px solid $grey-light;
    margin-top: 50px;

    &:hover {
      border: 2px solid $grey-light;
    }
  }
}

.rk-webgl-app {
  .rk-webgl__popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;

    .rk-webgl__popup-buttons__background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(27, 31, 56, 0.35);

        &.visible {
            display: flex;
        }
    }

    .rk-webgl__popup-buttons {
        width: 100%;
        height: 100%;
        display: none;

        &.visible {
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: auto;
            backdrop-filter: blur(10px);
        }
    }

    .rk-webgl__popup-buttons__row {
        width: 60%;

        @include md-down {
            width: 70%;
        }
    }

    .rk-webgl__popup-buttons__col {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .rk-webgl__popup-buttons__icon {
        width: 150px;
        height: 150px;

        @include xl-down {
            width: 100px;
            height: 100px;
        }

        @include md-down {
            width: 60px;
            height: 60px;
        }

        &.pointer {
            cursor: pointer;
        }
    }
    .rk-webgl__popup-buttons__story-title {
        position: absolute;
        top: 40px;
        left: 120px;
        height: 100px;
        display: flex;
        cursor: default;

        h1 {
            align-self: center;
            font: 900 40px/45px SFProDisplay;
            color: $white;
            margin: 0;
        }

        @include xl-down {
            top: 20px;
            left: 80px;
            height: 80px;
        }

        @include md-down {
            left: 20px;
            height: 50px;
            h1 {
                font: 900 25px/30px SFProDisplay;
            }
        }
    }

    .rk-webgl__popup-buttons__cog-icon {
        position: absolute;
        top: 40px;
        right: 120px;
        width: 100px;
        height: 100px;

        @include xl-down {
            top: 20px;
            right: 80px;
            width: 80px;
            height: 80px;
        }

        @include md-down {
            right: 20px;
            width: 50px;
            height: 50px;
        }

        &.pointer {
            cursor: pointer;
        }
    }

    .rk-webgl__popup-menu__container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;

        &.visible {
            backdrop-filter: blur(10px);
        }
    }

    .rk-webgl__popup-menu {
      $rk-webgl__popup-menu__padding: 40px;

      width: 540px;
      height: 370px;
      background: $white;
      border-radius: 30px;
      padding: $rk-webgl__popup-menu__padding;
      display: none;

      &.visible {
        display: grid;
        grid-template-rows: 1fr auto 1fr;
        pointer-events: auto;
      }

      h3 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 30px;
        font-weight: 700;
        flex-grow: 0;
        flex-shrink: 0;
      }

      .rk-webgl__popup-menu__content {
        display: flex;
        flex-direction: column;

        .rk-webgl__popup-menu__filler {
          flex-grow: 1;
          flex-shrink: 1;
        }

        label,
        .story-view-button--text,
        .ant-select-selector {
          font-size: 20px;
        }
      }

      .rk-webgl__popup-menu__row {
        min-height: 51px;
        font-size: 20px;
        border-top: 1px solid $black-low-sat;

        .rk-webgl__popup-menu__column {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          height: 100%;

          .rk-popup-option {
            margin-top: auto;
            margin-bottom: auto;
          }
        }

        &.two-columns {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 20px;

          .column-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
          }
        }
      }

      .rk-webgl__popup-menu__select-selector {
        cursor: pointer;
      }

      .rk-webgl__popup-menu__selection {
        position: absolute;
        z-index: 1050;
        margin: 50px 0 0 230px;
        width: 230px;
        min-height: 20px;
        display: none;
        padding: 4px;
        color: rgba(28, 34, 56, 0.88);
        font-size: 16px;
        line-height: 1.5;
        list-style: none;
        font-family: SFProDisplay, sans-serif;
        overflow: hidden;
        font-variant: initial;
        background-color: #fefefe;
        border-radius: 0;
        outline: none;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);

        &.visible {
            display: inline-block;
        }

        .ant-select-item {
          position: relative;
          display: block;
          min-height: 50px;
          padding: 13px 12px;
          color: rgba(28, 34, 56, 0.88);
          font-weight: normal;
          font-size: 16px;
          line-height: 1.5;
          cursor: pointer;
          transition: background 0.3s ease;
          border-radius: 0;

          &.ant-select-item-option-selected {
            color: rgba(28, 34, 56, 0.88);
            font-weight: 600;
            background-color: #FF8C68;
          }

          &:hover:not(.ant-select-item-option-selected) {
            background-color: rgba(28, 34, 56, 0.04);
          }
        }
      }
    }
  }
}
