@import "stylesheets/globals/colors";
@import "stylesheets/mixins/responsive";
@import "stylesheets/mixins/breakpoints";

.story-view--section {
  @include md-down {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.story-view {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: rgb(242, 242, 242);
}

.story-view--scroll-view {
}

.story-view--scroll-container {
  margin: 0;
  padding: 0;
  background-color: rgba(242, 242, 242, 0.8);
}

.story-view--related-section {
  margin-top: 50px;
}

.story-view--recorded-section {
  width: 100%;
  margin: 0 0 18px;
  padding: 0;
}

.story-view--recorded-by {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: rgb(28, 32, 57);
  flex-wrap: wrap;
  white-space: normal;
}

.story-view--record-button {
  background-color: rgb(242, 242, 242);
  border: 4px solid rgb(28, 32, 57);
  color: rgb(28, 32, 57);
}

.story-view--multi-button-row {
  max-width: 300px;
  flex-direction: row;
  justify-content: space-between;
}

.story-view--multi-button-row .story-view-button {
  max-width: 145px;
}

.story-view--version-info {
  width: 25px;
  height: 25px;
  margin: 0;
  margin-left: auto;
  padding: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  border-radius: 12.5px;
  cursor: pointer;
}

.story-info--button-icon {
  height: 25px;
  width: 25px;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.story-view--language-selection {
  width: 150px;
  margin: auto 0;

  @include sm-down {
    width: 150px;
  }

  @include xs-down {
    width: 100px;
  }

  .ant-select-selection-item {
    color: $black;
    text-align: right;
  }

  .ant-select-arrow {
    color: $black;
  }

  &.white {
    color: $white;

    .ant-select-selection-item {
      color: $white;
    }

    .ant-select-arrow {
      color: $white;
    }
  }
}
