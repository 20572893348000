.rk-parallelogram {
    display: flex;
    width: 270px;
    height: 50px;
    transform: skew(-45deg);
    margin: 0;
    margin-left: 25px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;

    .rk-parallelogram-label {
        text-align: center;
        vertical-align: middle;
        transform: skew(25deg);
        text-transform: uppercase;
        font: 600 22px/25px "SFProDisplay", sans-serif;
    }
}
