@import "stylesheets/globals/colors";
@import "stylesheets/mixins/responsive";

.rk-catalogue {
  width: 100%;
  overflow-x: hidden;
}

.rk-catalogue--title-section {
  padding: 0 15px;
}

.rk-catalogue--header {
  font-variant: normal;
  font-style: normal;
  font-family: SFProDisplay, sans-serif;
  font-size: 32px;
  text-transform: capitalize;
  margin-bottom: 30px;

  @include sm-down {
    text-align: center;
    font-size: 30px;
  }

  @include xs-down {
    font-size: 24px;
  }
}

.rk-catalogue-row {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  height: 490px;
  margin: 0 auto 0 auto;
  max-width: calc((250px * 6) + (40px * 6));

  &.centered {
    // We subtract 2 px for the anti-aliasing showing a line of the next image sometimes.
    max-width: calc((250px * 3) + (40px * 3) - 2px);

    @include md-down {
      max-width: calc(100%);
    }
  }

  &.right {
    .rk-story-panel {
      padding-right: 25px;
      padding-left: 15px;

      @include sm-down {
        padding-right: 0;
        padding-left: 15px;
      }
    }
  }

  &.short {
    height: 403px;
  }
}

.rk-catalogue-row-slider {
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  white-space: nowrap;
  position: relative;
  padding: 0;
}

.rk-catalogue-row-btn-left,
.rk-catalogue-row-btn-right {
  position: absolute;
  top: 200px;
  bottom: 120px;
  width: 70px;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background: rgba(28, 34, 56, 0.3);
  color: $black;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in;

  @include sm-down {
    width: 35px;
  }

  &:active,
  &:focus {
    background: rgba(28, 34, 56, 0.3);
    color: $white;
  }

  &:not(:disabled):hover {
    background: rgba(28, 34, 56, 0.3);
    color: $white;
  }

  svg {
    margin-top: 10px;
    margin-bottom: auto;
    height: 20px;
    width: 30px;

    @include sm-down {
      width: 15px;
    }
  }
}

.rk-catalogue-row:hover .rk-catalogue-row-btn-left,
.rk-catalogue-row:hover .rk-catalogue-row-btn-right {
  visibility: visible;
  opacity: 1;
}

.rk-catalogue-row-btn-left {
  left: 0;
}

.rk-catalogue-row-btn-right {
  right: 0;
}
