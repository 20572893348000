@import "stylesheets/mixins/responsive";
@import "stylesheets/globals/_globals";

.policy--pages {

  h1 {
    font: 700 70px/80px Morion, serif;
    margin: 40px 0;
    color: $black;

    @include md-down {
      font: 700 60px/70px Morion, serif;
    }
  }

  h2 {
    font: 700 20px/30px Morion, serif;
    text-align: left;
    margin: 40px 0 20px 0;
    color: $black;
  }

  h3 {
    font: 300 18px/25px SFProDisplay, sans-serif;
    color: $black;
    text-align: left;

    @include md-down {
      font: 300 16px/22px SFProDisplay, sans-serif;
    }
  }

  p {
    text-align: left;
    font: 300 17px/22px SFProDisplay, sans-serif;
    margin-bottom: 5px;
    text-transform: none;
  }

  a {
    font-weight: 500;
    letter-spacing: 0;
    padding: 0 3px;
    color: $black;
    border-bottom: 0.25px solid $black;
  }

  ul {
    li {
      text-align: left;
      font: 300 16px/22px SFProDisplay, sans-serif;
      margin-bottom: 5px;

      ul {
        li {
          list-style-position: inside;
        }
      }
    }
  }

  .ol-letters {
    list-style-type: lower-alpha;
  }

  h2.custom-list-title {
    counter-increment: h2-counter;
    counter-reset: custom-list-counter;
  }

  .custom-list {
    counter-increment: custom-list-counter;
    margin-bottom: 13px;
  }
  .custom-list::before {
    content: counter(h2-counter) "." counter(custom-list-counter);
    padding-right: 0.8em;
  }

  .no-decoration {
    list-style-type: none;
    text-align: left;
    font: 300 16px/22px SFProDisplay, sans-serif;;
  }
}