@import "../mixins/responsive";

.ant-drawer-content-wrapper {

  @include sm-down {
    width: 100%;
  }

  @include md-only {
    width: 500px;
  }

  @include lg-only {
    width: 600px;
  }

  @include xl-up {
    width: 700px;
  }
}
