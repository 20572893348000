@import "stylesheets/mixins/responsive";
@import "stylesheets/globals/_globals";

$homepage-tile-padding-width: 30px;
$homepage-tile-padding-height: 30px;
$homepage-tile-padding-width-md: 20px;
$homepage-tile-padding-height-md: 20px;

.homepage--section {
  margin-bottom: 75px;
}

.homepage--nav {
  height: 0;
  position: relative;
  z-index: 1;

  .homepage--rk-nav-link {

    a {
      font: 400 20px/25px SFProDisplay;
      color: $white;
      text-transform: uppercase;
      text-align: right;
      border-bottom: 2px transparent solid;

      &:hover {
        border-bottom: 2px $orange solid;
        color: $white;
      }

      @include xs-down {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .rk-btn {
      transform: translateY(-10px);
    }

    .ant-col {
      text-align: right;
      margin-left: 20px;
    }

    .link-buttons {
      display: flex;
      justify-content: right;
      flex-direction: row-reverse;
    }

    .page-buttons {
      display: flex;
      justify-content: right;
      flex-direction: row-reverse;
      margin-right: 50px;

      a {
        text-transform: capitalize;
      }

      @include sm-down {
        display: none;
      }
    }
  }
}

.homepage--header {
  .homepage--header-logo {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .homepage--header-controls {
    display: flex;
    flex-direction: row-reverse;
    padding: 34px 0;

    a {
      font: 300 20px/25px SFProDisplay;
      text-transform: uppercase;
    }
  }
}

.homepage--banner {
  max-height: 90vh;
  min-height: 80vh;
  overflow: hidden;
  margin-bottom: 30px;
  min-width: 100%;

  @include sm-down {
    max-height: 85vh;
    min-height: 80vh;
  }

  .homepage--banner-price {
    p {
      margin-top: 8px;
      font-size: 16px;
      line-height: 18px;
    }
  }

  .homepage--banner__image {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    min-height: 90vh;
    overflow: hidden;
  }

  video {
    height: 100vh;
    min-height: 100vh;

    @include xl-up {
      height: unset;
    }
  }

  img {
    object-fit: cover;
    object-position: center;
    min-width: 100vw;
    position: absolute;
    bottom: 0;

    &.portrait {
      display: none;
    }

    &.landscape {
      display: block;
    }

    @include sm-down {
      &.portrait {
        display: block;
      }

      &.landscape {
        display: none;
      }
    }
  }

  .homepage--banner-text {
    position: absolute;
    bottom: 4vh;
    text-align: left;

    @include lg-down {
    bottom: 2vh;
    }

    @include sm-down {
      width: 100%;
      bottom: 40px;
      text-align: center;
    }

    .homepage--banner-price {
      p {
        margin-top: 8px;
        font-size: 16px;
        line-height: 18px;  
      }
    }

    a {
      font: 900 20px/25px SFProDisplay;
    }

    h1 {
      font: 700 70px/75px morion;
      letter-spacing: 0.5px;
      color: $white;
      margin: 0 auto 15px auto;

      @include lg-down {
        margin: 0 auto 10px auto;
        font: 900 60px/65px morion;
      }

      @include sm-down {
        margin: 0 auto 10px auto;
        font-size: 46px;
        line-height: 62px;
      }

      @include xs-down {
        font-size: 43px;
        line-height: 48px;
      }
    }

    h3 {
      font: 500 28px/37px SFProDisplay;
      letter-spacing: 0.3px;
      color: $white;
      margin-bottom: 15px;

      @include md-down {
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 25px;
      }

      @include xs-down {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }

    p {
      margin-bottom: 18px;
      font: 300 20px/25px SFProDisplay;
      color: $white;
      letter-spacing: 0.2px;

      @include sm-down {
        font-size: 16px;
        margin-bottom: 0;
      }
    }

    .homepage--banner-description {
      width: 70%;

      @include md-down {
        width: 90%;
      }

      @include sm-down {
        width: 100%;
      }
    }
  }

  .homepage--banner-register {
    display: grid;
    grid-template-columns: 1fr auto;
    max-width: 600px;
    margin: 0;
    overflow: hidden;

    @include sm-down {
      grid-template-columns: auto;
      max-width: 100%;
      justify-content: center;
    }

    input {
      border: none;
      padding: 0 15px;
      width: calc(100%);
      font: 300 20px/25px SFProDisplay;

      &:focus, &:focus-visible {
        box-shadow: none;
        border: none;
        outline: none;
      }

      @include sm-down {
        width: 100%;
        display: none;
        font: 300 16px/25px SFProDisplay;
      }
    }

    button {
      font-weight: 700;
      font-size: 20px;
      letter-spacing: 0.5px;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      background-color: $white;
      color: $black;
      max-width: 300px;
      min-width: 250px;
      height: auto;
      border-radius: 10px;
      box-shadow: none;
      padding: 15px 30px;

      @include xs-down {
        padding: 7px 30px;
        font-size: 16px;
      }
    }
  }

  .homepage--banner-down-button-section {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    max-height: 200px;

    .homepage--down-button-wrapper {
      margin: auto;

      .homepage--down-button {

        span {
          width: 35px;
          height: 22px;
          font-size: 35px;
        }
      }
    }
  }
}

.homepage--favourites-catalogue {
  margin-bottom: 10vh;
  max-width: 100%;

  @include sm-down {
    margin-bottom: 0;
  }
}

.homepage--info-tiles {
  margin-left: 15px;
  margin-right: 15px;

  .homepage--info-tile {
    position: relative;
    min-height: 450px;
    max-height: 450px;

    @include sm-down {
      min-height: 400px;
      height: auto;
    }
  }

  .homepage--info-tile-background {
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .homepage--info-tile-detail {
    .homepage--info-tile-background {
      background: $orange-to-red;
    }
  }

  .homepage--info-tile-update {
    align-content: center;

    .homepage--info-tile-background-left {
      background: $purple;
      color: $white;
    }

    .homepage--info-tile-background-right {
      background: $aquamarine;
      color: $black;
    }
  }

  .homepage--info-tile-content {
    height: 100%;
    padding: $homepage-tile-padding-height $homepage-tile-padding-width;

    @include md-down {
      padding: $homepage-tile-padding-height-md $homepage-tile-padding-width-md;
    }

    &.white {
      color: white;

      h2,
      p {
        color: white;
      }

      a {
        color: white;
        border-bottom-color: white;
      }
    }
  }

  h2,
  p {
    text-align: left;
    font-weight: 300;
    font-family: SFProDisplay, sans-serif;
  }

  h2 {
    font-size: 30px;
    line-height: 35px;

    @include md-down {
      font-size: 25px;
      line-height: 30px;
    }

    @include xs-down {
      font-size: 20px;
      line-height: 25px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 10px;

    @include sm-down {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .homepage--tile-title {
    font-weight: 300;
  }

  .homepage--info-app-badge {
    text-align: center;

    svg {
      width: 270px;

      @include sm-down {
        max-width: 200px;
      }
    }

    img {
      width: 300px;
      @include sm-down {
        // It has 15px padding.
        max-width: 230px;
      }
    }
  }

  .homepage--no-style-btn {
    background: none;
    border: none;
    color: $white;
    font-weight: 300;
  }

  .homepage--underline-btn {
    border: none;
    background: none;
    text-decoration: underline;
    color: $white;
    font-weight: 700;
  }
}

.rk-homepage__title-section {
  padding: 0 15px;

  @include sm-down {
    padding: 0;
  }
}

.homepage--faqs-section {
  margin-top: 100px;

  @include sm-down {
    margin: 0 15px;
  }

  h2 {
    text-align: left;
    margin-bottom: 50px;

    @include sm-down {
      text-align: center;
      font-size: 30px;
    }
  }

  .ant-collapse.ant-collapse-borderless {
    background: transparent;
  }

  .faq-header {
    display: flex;
    min-width: 100%;
    font: 300 25px/35px SFProDisplay;
    align-items: center;

    @include xs-down {
      font: 300 20px/25px SFProDisplay;
    }

    .expand-icon {
      margin-left: auto;
      margin-right: 0;

      .icon {
        width: 35px;
        height: 35px;
        text-align: center;
        margin: 5px 0;

        @include xs-down {
          width: 24px;
          height: 24px;
          margin: 0;
        }
      }
    }
  }

  .ant-collapse-header[aria-expanded="true"] {
    .expand-icon {
      .icon {
        transform: rotateZ(45deg);
      }
    }
  }
}

.interactive-tile__row {
  min-height: 100%;

  .interactive-tile__wide-content-block {
    h2 {
      text-align: center;
      font: 700 45px/50px morion;
      color: $white;

      @include md-only {
        font: 700 35px/40px morion;
      }

      @include xs-down {
        font: 700 30px/35px morion;
      }
    }

    button {
      align-items: center;
    }

    .interactive-tile__wide-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 100%;
      position: relative;

      .interactive-tile__content-title {
        position: absolute;
        left: 0;
        top: 0;
      }

      .interactive-tile__content-buttons {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;

        a {
          margin-left: 15px;
          margin-right: 15px;
        }
      }
    }
  }

  .interactive-tile__image-wrapper {
    position: relative;
  }

  .interactive-tile__image {
    overflow: hidden;
    max-height: 100%;
    min-height: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    bottom: calc($homepage-tile-padding-height * -1);

    @include md-down {
      bottom: calc($homepage-tile-padding-height-md * -1);
      width: 100%;
    }

    img {
      display: flex;
      justify-content: center;
      object-fit: contain;
      width: 100%;
      height: 100%;
      object-position: bottom;
    }
  }
}

.interactive-tile__column {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 100%;
  min-height: 100%;

  .interactive-tile__image-wrapper {
    position: absolute;
    bottom: calc($homepage-tile-padding-height * -1);
    overflow: hidden;
    width: 100%;
    height: 100%;

    @include md-down {
      bottom: calc($homepage-tile-padding-height-md * -1);
    }
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-style: none;
    vertical-align: middle;
    object-position: bottom;
  }
}

.interactive-tile__content-center {
  vertical-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.interactive-tile__image-div {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
}
