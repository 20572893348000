@import "stylesheets/mixins/responsive";
@import "stylesheets/globals/colors";

.rk-register-content {
  margin-top: 50px;
  margin-left: 15px;
  margin-right: 15px;

  .rk-input {
    &.ant-input-affix-wrapper {
      background: white;
    }
  }

  .rk-chevron {
    display: block;
    height: 18px;
    width: auto;
  }

  button.rk-btn-dark {
    @include xs-down {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  button.rk-btn-secondary {
    padding-left: 50px;
    padding-right: 50px;

    @include md-down {
      font-size: 18px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include xs-down {
      padding-left: 40px;
      padding-right: 40px;
      font-size: 15px;
    }
  }

  .rk-use-saved-credit-card {
    margin-top: 20px;
  }

  .error-message-div {
    text-align: center;
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  .rk-register-form__token {
    font-size: 14px;
    padding: 0;
    margin: 0 0 5px 0;
  }

  .rk-register-form__error-message {
    font-size: 14px;
    color: $red;
    padding: 0;
    margin: 0 0 5px 0;
    text-align: center;
  }
}
