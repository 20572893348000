@import "colors";

.rk-menu {
  &.ant-menu-horizontal:not(.ant-menu-dark) {
    background: $grey-light;
    li {
      line-height: 23px;

      &:hover,
      &.ant-menu-item-selected {
        //color: $orange;
        //border-bottom: 2px solid $orange;
      }
    }
  }
}
