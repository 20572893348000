.ant-layout-header {
  &.rk-homepage-header {
    background: transparent;
    padding: 0 15px;
    height: 0;
    line-height: unset;
    position: absolute;
    top: 15px;
    z-index: 1;
    width: 100%;
  }
}