@import "breakpoints";

@mixin xs-up {
  @media (min-width: #{$screen-xs-min}) {
    @content
  }
}

@mixin xs-only {
  @media (min-width: #{$screen-xs-min}) and (max-width: #{$screen-xs-max}) {
    @content
  }
}

@mixin xs-down {
  @media (max-width: #{$screen-xs-max}) {
    @content
  }
}

@mixin sm-up {
  @media (min-width: #{$screen-sm-min}) {
    @content
  }
}

.hidden-sm-up {
  @include sm-up {
    display: none;
  }
}

@mixin sm-only {
  @media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
    @content
  }
}

.hidden-sm {
  @include sm-only {
    display: none;
  }
}

@mixin sm-down {
  @media (max-width: #{$screen-sm-max}) {
    @content
  }
}

.hidden-sm-down {
  @include sm-down {
    display: none;
  }
}

@mixin md-up {
  @media (min-width: #{$screen-md-min}) {
    @content
  }
}

@mixin md-only {
  @media (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
    @content
  }
}

@mixin md-down {
  @media (max-width: #{$screen-md-max}) {
    @content
  }
}

@mixin lg-up {
  @media (min-width: #{$screen-lg-min}) {
    @content
  }
}

@mixin lg-only {
  @media (min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max}) {
    @content
  }
}

@mixin lg-down {
  @media (max-width: #{$screen-lg-max}) {
    @content
  }
}

@mixin xl-up {
  @media (min-width: #{$screen-xl-min}) {
    @content
  }
}

@mixin xl-only {
  @media (min-width: #{$screen-xl-min}) and (max-width: #{$screen-xl-max}) {
    @content
  }
}

@mixin xl-down {
  @media (max-width: #{$screen-xl-max}) {
    @content
  }
}

@mixin xxl-up {
  @media (min-width: #{$screen-xxl-min}) {
    @content
  }
}

@mixin xxl-only {
  @media (min-width: #{$screen-xxl-min}) {
    @content
  }
}

@mixin xxl-down {
  @content
}
