.rk-chevron {
  &.right {

  }

  &.left {
    .chevron-path {
      transform-origin: 50% 50%;
      transform: rotate(180deg);
    }
  }

  &.up {
    .chevron-path {
      transform-origin: 50% 50%;
      transform: rotate(180deg);
    }
  }

  &.down {
  }
}
