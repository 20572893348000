.rk-help-link {
  text-align: left;
  font-size: 16px;
}

.rk-redirect-link {
  margin-top: 60px;

  p {
    font-size: 16px;
  }
}
