.ant-wave {
  // Hide the wave effect when clicking on things.
  box-shadow: none !important;
  color: transparent !important;
}

a {
  &.rk-btn {
    display: inline-block;
  }

  &.submit-form {
    text-align: center;
  }
}

.rk-input.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $orange;
}

a,
button {
  letter-spacing: 0.3px;

  &.rk-btn {
    padding: 15px;
    height: auto;
    min-width: 180px;
    font-size: 20px;
    line-height: 0.9;
    font-family: "SFProDisplay", sans-serif;
    font-weight: 400;
    color: $grey-light;
    background-color: $orange;
    outline-color: $orange;
    outline-style: none;
    border: 2px $orange solid;
    border-radius: 0;
    cursor: pointer;
    box-shadow: none;

    &.rounded {
      border-radius: 30px;
    }

    &:not(:disabled):active,
    &:not(:disabled):focus,
    &:not(:disabled):hover {
      color: $orange;
      background-color: $grey-light;
      border: 2px $orange solid;
    }

    &.ant-btn-sm {
      padding: 5px;
      min-width: 145px;
      font-size: 16px;
      font-weight: 400;
    }

    &.rk-btn-slim {
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      font-family: "SFProDisplay", sans-serif;
      padding: 8px 42px;
      text-align: center;
      text-decoration: underline;

      span {
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        font-family: "SFProDisplay", sans-serif;
        text-decoration: underline;
      }
    }
  }

  &.rk-cta-btn {
    color: $black;

    .rk-chevron {
      overflow: visible;
      margin-top: 3.5px;

      &.right {
        margin-left: 10px;
      }
    }
  }

  &.rk-btn-danger {
    @extend .rk-btn;
    color: $white;
    background-color: $red;
    outline-color: $red;
    border: 2px $red solid;

    &:active,
    &:focus,
    &:hover {
      color: $red;
      background-color: $white;
      border: 2px $red solid;
    }
  }

  &.rk-btn-secondary {
    @extend .rk-btn;
    color: $black;
    background-color: $white;
    outline-color: $black;
    border: 2px $black solid;

    &:not(:disabled):active,
    &:not(:disabled):focus,
    &:not(:disabled):hover {
      color: $orange;
      background-color: $grey-light;
      border: 2px $orange solid;
    }
  }

  &.rk-btn-dark {
    @extend .rk-btn;
    color: $white;
    background-color: $black;
    outline-color: $black;
    border: 2px $black solid;
    min-width: unset;
    padding-left: 50px;
    padding-right: 50px;

    &:not(:disabled):active,
    &:not(:disabled):focus,
    &:not(:disabled):hover {
      color: $orange;
      background-color: $white;
      border: 2px $orange solid;
    }
  }
}

.rk-captcha {
  margin-bottom: 25px;

  @include sm-down {
    transform: scale(0.70);
  }
}
