@import "stylesheets/mixins/responsive";

.rk-subscription-content {
  margin-top: 150px;

  .rk-input {
    &.ant-input-affix-wrapper {
      background: white;
    }
  }

  .rk-beta-key__form {

    .rk-beta-key__btn-container {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .rk-beta-key__errors {
      color: red;
      text-align: center;
      text-transform: capitalize;

      p {
        font-size: 14px;
      }
    }
  }
}

.rk-register--step {
  font-size: 14px;
  letter-spacing: 0.4px;
}

.summary--info-app-badge {
  text-align: center;
  margin-top: 45px;

  svg {
    width: 270px;

    @include sm-down {
      max-width: 200px;
    }
  }

  img {
    width: 300px;
    @include sm-down {
      // It has 15px padding.
      max-width: 230px;
    }
  }
}