@import "stylesheets/globals/colors";
@import "stylesheets/mixins/responsive";

.rk-story-multi-panel {
  $margin-right: 15px;
  $largest-width: 280px;
  $percentage-width: calc(33vw - $margin-right);
  $width: max(min($percentage-width, $largest-width), 280px);

  display: inline-block;
  position: relative;
  width: calc(100% - 30px);
  margin-left: 15px;

  .rk-story--title-section {
    margin-bottom: 20px;

    @include md-down {
      margin: 0 15px 20px 0;
    }
  }

  .rk-story-multi-panel--title-text-container,
  .rk-story-multi-panel--description-text-container {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
    padding: 0;
    font-variant: normal;
    font-style: normal;
    font-family: SFProDisplay, sans-serif;
    letter-spacing: 0.2px;
    text-align: left;
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: pre-wrap; /* css-3 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
    white-space: normal;
    color: $black;
    overflow: hidden;
    text-overflow: ellipsis;

    @include md-down {
      -webkit-line-clamp: 3;
    }
  }

  .rk-story-multi-panel--title-text-container {
    font-size: 30px;
    line-height: 35px;
  }

  .rk-story-multi-panel--description-text-container {
    font-size: 16px;
    line-height: 35px;

    @include md-down {
      font-size: 16px;
      line-height: 21px;
    }
  }

  .rk-story-multi-panel--images-row {
    max-width: calc($largest-width * 3 + $margin-right * 2);
    margin-left: auto;
    margin-right: auto;
  }

  .rk-story-multi-panel--image {

    margin: 0 $margin-right 0 0;
    padding: 0;
    border: 0;
    overflow: hidden;
    position: relative;

    // Set it to be square.
    width: $width;
    height: $width;
    min-width: $width;
    min-height: $width;
    max-width: $width;
    max-height: $width;

    &:active,
    &:focus,
    &:hover {
      outline: none;
      margin: 0 $margin-right 0 0;
      padding: 0;
      border: 0;
    }

    &:hover {
      cursor: pointer;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
    }
  }
}


