@import "stylesheets/mixins/responsive";

.rk-signup-content {
  margin-top: 50px;

  @include sm-down {
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  input {
    font-size: 20px;
    min-width: 100%;
  }

  .submit-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit-form {
    margin: 0 auto;
  }
}
