@import "stylesheets/mixins/responsive";

.story-view--toggle {
  height: 50px;
  display: block;
}

.story-view--toggle-container {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .story-view-button--icon {
    margin: 0;
  }

  .rk-switch {
    margin: auto 0 auto auto;
  }
}

