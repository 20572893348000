@import "stylesheets/globals/colors";

.story-view-header {
  min-height: 65px;

  .rk-chevron {
    min-width: 13px;
    min-height: 21px;
    color: $black;

    &:hover {
      color: $orange;
    }
  }
}
