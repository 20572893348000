@import "stylesheets/mixins/responsive";

.story-info--section {
  background: rgb(255, 255, 255);
  padding-bottom: 150px;
  margin: 150px 0;

  @include sm-down {
    margin: 0;
    padding-bottom: 0;
  }
}

.story-info--description-section {
  width: 100%;
  margin: 40px 0 33px;
  padding: 0;
  overflow: hidden;
}

.story-info--description-title,
.story-info--description-body {
  margin: 0;
  padding: 0;
  flex-grow: 1;
  color: #1C2039;
  flex-wrap: wrap;
  white-space: normal;
  font-family: SFProDisplay, sans-serif;
}

.story-info--description-title {
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 20px;

  @include sm-down {
    font-size: 24px;
  }
}

.story-info--description-body {
  font-size: 16px;
  line-height: 18px;
}

.story-info--item {
  width: 100%;
  max-width: 300px;
  margin: 0 0 30px;
  padding: 0;
  display: flex;
  flex-direction: column;

  @include sm-down {
    margin: 0 0 20px;
  }
}

.story-info--title,
.story-info--body {
  margin: 0;
  padding: 0;
  flex-grow: 1;
  color: #1C2039;
  flex-wrap: wrap;
  white-space: normal;
  font-size: 16px;
  line-height: 20px;
  font-family: SFProDisplay, sans-serif;
  font-weight: 300;
}

.story-info--title {
  font-weight: 400;
}
