.credits-view-drawer .ant-drawer-body {
  min-width: 340px;
  background-color: rgb(28, 32, 57);
}

.credits-view {
  font-size: 16px;
  color: rgb(255, 255, 255);
  min-width: auto;
  min-height: auto;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.credits-view--row {
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.credits-view--column {
  overflow: hidden;
}

.credits-view--left-column {
  width: 100%;
  min-height: 20px;
  padding: 10px 20px;
  white-space: normal;
  text-align: right;
}

.credits-view--right-column {
  width: 100%;
  min-height: 20px;
  padding: 10px 20px;
  white-space: normal;
  text-align: left;
}

.credits-view--loading {
  padding: 24px;
}
