.rk-loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.9);
  z-index: 10;

  .rk-spinner {
    width: 50px;
    height: 50px;
  }
}
