.rk-form-container {
  background: white;
  padding: 50px 50px;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 15px;
  max-width: 500px;
  margin: 0 auto;

  @include sm-down {
    padding: 50px 25px;
  }

  h1, h2 {
    font: 900 30px/35px SFProDisplay;

    @include sm-down {
      font: 900 26px/30px SFProDisplay;
    }
  }

  h1 {
    text-transform: uppercase;
  }

  input {
    background: white;

    @include sm-down {
      font: 300 16px/25px SFProDisplay;
    }
  }

  .ant-form-item-control-input {
    min-height: unset;
  }
}

.rk-form-item__padding_tight {
  margin-bottom: 10px;
}

.ant-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  font-weight: 300;
  line-height: 20px;

  .ant-checkbox {
    top: 0;

    .ant-checkbox-inner::after {
      inset-inline-start: 30%;
    }
  }

  .ant-checkbox-input {
    height: 25px;
    width: 25px;
    top: 0;
  }
}
