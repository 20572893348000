@import "stylesheets/mixins/responsive";
@import "stylesheets/globals/_globals";

.rk-about-layout {
}

.aboutpage--banner {
  padding: 15vh 0 0 0;

  @include xl-down {
    padding: 5vh 0 0 0;
  }

  .video-banner {
    z-index: -2;
    position: absolute;
    width: 100%;
    top: 0;
    max-height: 634px;
    overflow: hidden;

    .video-banner-content {
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
    }

    video {
      min-height: 100%;
      min-width: 100%;
      object-position: bottom;
    }

    .faded-background {
      position: absolute;
      background: transparent linear-gradient(180deg, #F2F2F200 0%, #F2F2F2FD 100%) 0 0 no-repeat padding-box;
      height: 50%;
      bottom: 0;
      width: 100%;
    }

  }
}
.about--section {
  margin-top: 15vh;

  @include md-down {
    margin-top: 10vh;
  }

  &.large-top-margin {
    margin-top: 30vh;

    @include md-down {
      margin-top: 10vh;
    }
  }
}

.aboutpage--section-text {
  text-align: center;

  .light {
    color: $white;

    @include xs-down {
      color: $black;
    }
  }

  &.left {
    text-align: left;
  }

  h1 {
    font: 700 80px/90px Morion, serif;
    margin-bottom: 40px;
    color: $black;

    @include md-down {
      font: 700 60px/70px Morion, serif;
    }
  }

  h2 {
    font: 700 55px/65px Morion, serif;
    margin-bottom: 40px;
    color: $black;

    @include md-down {
      font: 700 45px/55px Morion, serif;
    }
  }

  h3 {
    font: 400 25px/30px SFProDisplay, sans-serif;
    color: $black;

    @include xs-down {
      font: 300 20px/25px SFProDisplay, sans-serif;
    }
  }

  p {
    font: 500 16px/20px SFProDisplay, sans-serif;
    text-transform: uppercase;
    margin-bottom: 30px;
    color: $black;
  }

  @include sm-down {
    padding: 0 15px;
  }
}

.aboutpage--image {
  overflow: hidden;
  text-align: center;
  margin: 20px 100px;
  display: flex;

  img {
    aspect-ratio: 16/9;
    object-fit: cover;
    max-height: 600px;
    object-position: center center;
    max-width: 100%;
  }
}

.about--tile-images {
  margin: 50px auto;

  p {
    font-size: 20px;

    @include sm-down {
      font-size: 16px;
    }
  }
}

.about--tile-wrapper {
  display: grid;
  grid-template-columns: 50%;
  grid-gap: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include sm-down {
    grid-template-columns: 100%;
  }
}

.about--title-wrapper, .block-together {
  @include sm-down {
    grid-template-columns: 50%;
    grid-gap: 0;
  }
}

.section-image-left {
  min-height: 500px;

  img {
    object-position: right top;
    min-height: 700px;
    overflow: visible;
    height: 100%;
    aspect-ratio: 16 / 9;

    @include sm-down {
     min-height: 450px;
    }
  }
}

.section-image-right {
  padding-top: 60px;
  overflow: hidden;
  width: 600px;

  img {
    object-position: left top;
    height: 100%;
  }

  @include sm-down {
    padding-top: 40px;
    width: 400px;
  }
}

.section-image-single {
  min-height: 500px;

  img {
    height: 100%;
    object-position: center;
  }

  .overflow-image {
    overflow: visible;
    object-fit: contain;
    width: min(70vw, 900px);

    @include sm-down {
      width: 100%;
    }
  }

  @include sm-down {
    min-height: auto;
  }
}

.about-section-content {
  margin-top: 15px;
  font: 300 24px/29px SFProDisplay, sans-serif;
  align-items: center;

  p {
    font: 300 20px/25px SFProDisplay, sans-serif;
    padding: 5px 0 0 0;

    @include sm-down {
      padding: 0 15px;
      font: 300 18px/23px SFProDisplay, sans-serif;
    }
  }
}

.about--text-block-title {
  font-weight: 500;
  letter-spacing: 0.2px;
  padding: 0;
  margin: 0 0 5px 0;
}

.about-image-with-text {
  display: grid;
  overflow: hidden;
  grid-template-areas:
          "block1 block2";

  @include sm-down {
    grid-template-areas:
          "block1" "block2";
  }

  &.left {
    grid-template-areas:
          "block2 block1";

    @include sm-down {
      grid-template-areas:
           "block1" "block2";
    }
  }

  .block1 {
    grid-area: block1;
  }

  .block2 {
    grid-area: block2;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include lg-down {
      justify-content: start;
      padding-top: 80px;
    }

    @include md-down {
      padding-top: 0;
    }
  }

  .text-block-right {
    padding: 45px 300px 45px 0;

    @include xl-down {
      padding: 45px 150px 45px 50px;
    }

    @include lg-down {
      padding: 25px 50px 25px 50px;
    }

    @include md-down {
      padding: 25px 15px;
    }
  }

  .text-block-left {
    padding: 45px 0 45px 350px;

    @include xl-down {
      padding: 45px 0 45px 200px;
    }

    @include lg-down {
      padding: 25px 0 25px 100px;
    }

    @include md-down {
      padding: 25px 15px;
    }
  }

  .image-right {
    margin-left: -200px;

    @include lg-down {
      margin-left: -150px;
    }

    @include md-down {
      margin-left: -100px;
    }

    @include sm-down {
      margin-left: 0;
    }

  }
}

.about-images-with-text {
  display: grid;
  grid-template-areas:
          "block1 block2"
          "block1 block3";

  @include xs-down {
    grid-template-areas:
          "block1 block2"
          "block3 block3";
  }

  .block1 {
    grid-area: block1;
  }

  .block2 {
    grid-area: block2;
    overflow: hidden;
  }

  .block3 {
    grid-area: block3;
    margin-top: 0;
    padding-right: 350px;

    @include xl-down {
      padding-right: 250px;
    }

    @include lg-down {
      padding-right: 70px;
    }

    @include sm-down {
      padding-right: 15px;
    }

    @include xs-down {
      padding: 0 15px;
    }
  }
}

.about--section-dual-image {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 10px;
  overflow: hidden;

  .about--image {
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &:first-child {
      margin-top: 60px;

      img {
        object-position: right top;
      }
    }
  }
}
