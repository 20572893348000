@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(359deg);
  }
}

.rk-spinner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .rk-spinner-container {
    margin-left: auto;
    margin-right: auto;

    .rk-spinner {
      position: absolute;
      animation-name: spinner;
      animation-duration: 1.5s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      will-change: transform;
      display: inline-block;
      transform: translate(-50%, -50%);
    }
  }
}
