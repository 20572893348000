@import "stylesheets/globals/colors";

.ant-btn {
  &.btn-success {
    color: $admin-green;
    border-color: $admin-green;

    &:hover,
    &:focus {
      color: $admin-green-hover-light;
      border-color: $admin-green-hover-light;
    }
  }
}
