h1 {
  font-size: 55px;
  font-family: "SFProDisplay", sans-serif;
  font-weight: 300;
  line-height: 1.3;

  @media (max-width: #{$screen-xs-max}) {
    font-size: 45px;
  }
}

h2 {
  font-size: 30px;
  font-family: "SFProDisplay", sans-serif;
  font-weight: 300;
  line-height: 1.3;

  @media (max-width: #{$screen-xs-max}) {
    font-size: 20px;
  }
}

label, div {
  font-family: "SFProDisplay", sans-serif;
}

.paragraph,
p {
  font-size: 20px;
  font-family: "SFProDisplay", sans-serif;
  font-weight: 300;
  line-height: 1.3;

  @media (max-width: #{$screen-xs-max}) {
    font-size: 18px;
  }


  a {
    color: $black;
    border-bottom: 0.25px $black solid;

    &:hover {
      color: $black;
      border-bottom-color: $orange;
    }

    &.orange-underline {
      border-bottom-color: $orange;

      &:hover {
        border-bottom-color: $black;
      }
    }

    &.solid {
      border-bottom-width: 1px;
    }
  }

  strong {
    font-weight: 400;
  }
}

.rk-link {
  color: $black;
  border-bottom: 0.25px $black solid;

  &:hover {
    color: $black;
    border-bottom-color: $orange;
  }

  &.orange-underline {
    border-bottom-color: $orange;

    &:hover {
      border-bottom-color: $black;
    }
  }

  &.solid {
    border-bottom-width: 1px;
  }
}

.rk-link-blue {
  color: $blue;
  border-bottom: 0.25px $blue solid;

  &:hover {
    color: $blue;
    border-bottom-color: $blue;
  }
}

.error-message {
  color: $red;
}

.link-pointer {
  cursor: pointer;
}

a {
  .anticon {
    color: $black;

    &:hover {
      color: $orange;
    }
  }
}

.ant-typography-expand, .ant-typography-edit, .ant-typography-copy {
  color: $orange !important;

  &:active,
  &:hover {
    color: $orange;
  }
}

html {
  --antd-wave-shadow-color: #FF8C68; // this has to be hard coded. :(
}

