.rk-invoices-table {
  min-width: 913px;
  font-family: "Courier", sans-serif;

  .ant-table-filter-trigger-container {
    padding: 0 20px;

    .anticon {
      font-size: 20px;
    }
  }

  .ant-table-filter-column-title {
    font-size: 20px;

    .anticon {
      font-size: 16px;
    }
  }
}
