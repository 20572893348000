
@import "stylesheets/globals/colors";

.subscription-manage__stories {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: center;

  .story {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    margin: 5px;

    .story__image {
      position: relative;
      width: 200px;
      height: 280px;
      cursor: pointer;
    }

    .story__blocked {
      z-index: 1;
      opacity: 0;
      transition: opacity 0.25s linear;
      position: absolute;
      left: 0;
      right: 0;
      background: $orange;
      top: 0;
      bottom: 0;
    }

    .story__blocked__text {
      z-index: 2;
      opacity: 0;
      transition: opacity 0.25s linear;
      position: absolute;
      color: $white;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 36px;
      font-weight: 600;
    }

    &.blocked {
      .story__blocked {
        background: $red;
      }
    }

    &.blocked,
    &:hover {
      .story__blocked {
        opacity: 0.7;
      }

      .story__blocked__text {
        opacity: 1;
      }

      img {
        filter: grayscale(100%);
      }

      .title {
        color: $orange_low-sat;
      }
    }

    img {
      max-height: 280px;
      max-width: 200px;
      width: 200px;
      object-fit: cover;
      transition: 0.25s filter linear, 0.25s -webkit-filter linear;
    }
  }
}
