@import "stylesheets/mixins/responsive";
@import "stylesheets/globals/_globals";

.rk--subscription-modal {
  padding: 70px;

  @include xs-down {
    padding: 5%; 
  }
}

button.rk--newsletter-button {
  min-width: 100%;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 22px;
  line-height: 27px;
  font-weight: 400;
  font-family: "SFProDisplay", sans-serif;
  background: $orange-to-red;
  color: $white;
  margin: 30px 0 0 0;
  letter-spacing: 0.32px;
  border-width: 0;
}

.newsletter--modal-input {
  text-align: center;

  input {
    margin-bottom: 15px;
  }
}

.newsletter--modal-content {
  h3 {
    font-size: 30px;
    line-height: 35px;
  }
  
  p {
    font-style: 300 22px/27px SFProDisplay, sans-serif;
  }
}