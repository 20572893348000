.rk-page-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50px;

  .rk-page-title--content {
    display: flex;
    flex-direction: row;
  }

  .rk-page-title--back-arrow {
    margin-right: 10px;
    overflow: hidden;
    max-height: 32px;
    height: 32px;
    margin-bottom: 0;
    cursor: pointer;

    svg {
      height: 32px;
      width: 32px;
    }
  }

  .rk-page-title--title {
    font-size: 30px;
    margin-bottom: 0;
  }

  .rk-page-title--subtitle {
    margin-left: 20px;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0;
  }
}
