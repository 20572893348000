// ------------------------------------
// Core Colors
// ------------------------------------
// Only use the colours that are provided here.
// If you need a new colour, please ask the design team.
$black: #010114;

$white: #FEFEFE;

$grey-light: #F2F2F2;

$orange: #FF8C68;
$purple: #7D4DD8;
$aquamarine: #05EDBF;

$yellow: #FCDE4C;
$pink: #F8B6FD;
$red: #F94141;
$blue: #0074FF;

// ------------------------------------
// Low Saturation
// ------------------------------------
// These colours should only be used for items that are disabled
// or potentially with a hover state.
$orange-low-sat: #F6C8B9;
$purple-low-sat: #C8C1FA;
$aquamarine-low-sat: #D0FDF4;
$black-low-sat: #888b95;
$white-low-sat: #888b95;

// ------------------------------------
// Box Shadows
// ------------------------------------
// These box shadows are for coloring with the core colors
$black-box-shadow: #1C223828;

// ------------------------------------
// Admin Colors
// ------------------------------------
// These colors are not on brand so should only be used in the admin panel
// Or on pages that are exclusively for admins.
$admin-grey-dark: #888888;
$admin-green: #228B22;
$admin-green-hover-light: #63B26D;


// ------------------------------------
// Gradients
// ------------------------------------
// Gradients
$purple-to-pink: transparent linear-gradient(180deg, $purple 0%, $pink 100%) 0 0 no-repeat padding-box;
$orange-to-red : transparent linear-gradient(180deg, $orange 0%, $red 100%) 0 0 no-repeat padding-box;


.rascal_orange {
  color: $orange;
}
.rascal_purple {
  color: $purple;
}
.rascal_aquamarine {
  color: $aquamarine;
}

.rascal_red {
  color: $red;
}
.rascal_blue {
  color: $blue;
}
.rascal_pink {
  color: $pink;
}
.rascal_yellow {
  color: $yellow;
}