body {
  background-color: $grey-light;

  hr {
    border: 0.5px solid $black;
    width: 100%;
  }
}

.ant-layout {
  background-color: $grey-light;
}
