@import "stylesheets/globals/colors";
@import "stylesheets/mixins/responsive";

.rk-subscriptions {
  ul {
    padding: 0 0 0 20px;
    margin: 0;
    font-size: 18px;

    li {
      padding-bottom: 15px;

      @include sm-down {
        padding-bottom: 10px;
      }
    }
  }
}

.rk-beta-key__form {
  h2 {
    margin-bottom: 30px;
  }
}

.rk-subscriptions__container {
  min-width: 100%;
}

.rk-subscription-form__subscriptions {
  grid-column-gap: 15px;
  grid-row-gap: 15px;

  .rk-subscriptions--card-container {
    height: 100%;
  }

  .rk-subscriptions--card-container:nth-child(2n + 1) {
    @include sm-up {
      margin-right: auto;
    }

    @include xs-down {
      min-width: 100%;
      width: 100%;
    }
  }

  .rk-subscriptions--card-container:nth-child(2) {
    @include sm-up {
      margin-left: auto;
    }

    @include xs-down {
      min-width: 100%;
      width: 100%;
    }
  }
}

.rk-subscriptions--radio {
  height: calc(100% - 15px);
  margin: 0;

  @include xs-down {
    width: 100%;
    min-width: 100%;
    flex-grow: 1;
  }

  span.ant-radio + * {
    padding: 0;
    display: block;
    min-width: 100%;
  }

  .ant-radio {
    position: absolute;
    opacity: 0;
    display: none;
  }

  .rk-subscriptions--card {
    min-height: 100px;
    height: auto;
    width: 180px;
    margin: 0;
    padding: 10px;
    border: 2px solid $black;
    border-radius: 10px;
    display: grid;
    grid-template-columns: auto 1fr;

    @include sm-only {
      width: 200px;
    }

    @include xs-down {
      width: 100%;
    }

    .rk-subscriptions--card__info {
      margin-top: auto;
      margin-bottom: auto;
    }

    .rk-subscriptions--icon {
      width: 160px;
      height: 160px;
      object-fit: cover;
      padding-bottom: 10px;
    }

    .ant-card-meta-title,
    .ant-card-meta-description {
      padding: 5px 0;
      margin-bottom: 0;
    }

    .ant-card-body {
      padding: 5px 20px;
    }

    .rk-subscriptions--hr {
      margin: 10px 0;
      border-top: 1px solid #f0f0f0;
    }

    .rk-subscriptions--card__price {
      margin-top: 4px;
      padding-bottom: 0;
      margin-bottom: 0;
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;

      .subtitle {
        display: block;
        font-size: 12px;
        font-weight: 300;
        text-transform: none;
      }

      .card__price__old {
        text-decoration: line-through;
      }

      .card__price__new {
        color: $orange;
        display: block;
      }
    }

    .rk-subscriptions--card__title {
      margin-bottom: 0;
      font-weight: 700;
    }

    .rk-subscriptions--card__tick-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
        padding: 0 10px;
    }
  }

  &.ant-radio-wrapper-checked {
    .rk-subscriptions--card {
      border-color: $orange;
    }
  }
}

.rk-subscriptions--input {
  width: 320px;
  margin: 0 auto;
}
