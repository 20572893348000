.rk-edit-value-in-place {
  &.rk-editable-inline-block {
    display: inline-block;
  }

  &.ant-typography-edit-content {
    left: 0;
    margin-top: -18px;
    margin-bottom: 0;

    .ant-input {
      height: 32px !important;
      padding: 5px !important;
      overflow-y: hidden;
      resize: none;
    }

    .ant-typography-edit-content-confirm {
      bottom: 5px;
    }
  }
}

.rk-edit-paragraph-in-place {
  &.ant-typography-edit-content {
    left: 0;
    margin-top: 0;
    margin-bottom: 1em;

    .ant-input {
      min-height: 120px;
    }
  }
}
