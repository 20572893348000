@import "stylesheets/globals/colors";
@import "stylesheets/mixins/responsive";

.rk-story-panel {
  $story-panel-description-font-size: 16px;
  $story-panel-description-line-height: 1.2;
  $story-panel-description-lines-to-show: 2;

  display: block;
  padding-right: 40px;

  .rk-story-panel--image__button {
    margin: 0;
    padding: 0;
    border: 0;
    overflow: hidden;
    position: relative;

    &:active,
    &:focus,
    &:hover {
      outline: none;
      margin: 0;
      padding: 0;
      border: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .rk-story-panel--image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .rk-story-panel--downloaded-icon-container {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 5px 4px;
  }

  .rk-story-panel--downloaded-icon {
    height: 30px;
    width: 19px;
    margin: 0;
    padding: 0;
  }

  .rk-story-panel--message-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 30px;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: rgb(255, 255, 255);
  }

  .rk-story-panel--new-stories-message {
    background-color: rgb(255, 140, 104);
  }

  .rk-story-panel--coming-soon-message {
    background-color: rgb(119, 79, 255);
  }

  .rk-story-panel--description {
    position: relative;
    margin: 0;
    padding: 18px 0;
  }

  .rk-story-panel--genre-text-container,
  .rk-story-panel--title-text-container,
  .rk-story-panel--description-text-container {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
    padding: 0;
    text-align: left;
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: pre-wrap; /* css-3 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
    white-space: normal;
    color: rgb(28, 32, 57);
    overflow: hidden;
    text-overflow: ellipsis
  }

  .rk-story-panel--genre-text-container {
    font-size: 12px;
    text-transform: uppercase;
  }

  .rk-story-panel--title-text-container {
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 5px;
  }

  .rk-story-panel--description-text-container {
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    max-width: 400px;
    height: $story-panel-description-font-size*$story-panel-description-line-height*$story-panel-description-lines-to-show; /* Fallback for non-webkit */
    margin: 0 auto;
    font-size: $story-panel-description-font-size;
    line-height: $story-panel-description-line-height;
    -webkit-line-clamp: $story-panel-description-lines-to-show;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.rk-story-panel--tall {
  $story-panel-tall-width: 250px;
  $story-panel-tall-height: 347px;
  $story-panel-tall-height-sm: 278px;
  $story-panel-tall-width-sm: 200px;

  .rk-story-panel--image__button {
    width: $story-panel-tall-width;
    height: $story-panel-tall-height;

    @include sm-down {
      width: $story-panel-tall-width-sm;
      height: $story-panel-tall-height-sm;
    }
  }

  .rk-story-panel--description {
    width: $story-panel-tall-width;

    @include sm-down {
      width: $story-panel-tall-width-sm;
    }
  }
}

.rk-story-panel--short {
  $story-panel-short-width: 260px;
  $story-panel-short-height: 260px;

  .rk-story-panel--image__button {
    width: $story-panel-short-width;
    height: $story-panel-short-height;
  }

  .rk-story-panel--description {
    width: $story-panel-short-width;
  }
}

.rk-story-panel--padding-left {
  padding-right: 25px;
  padding-left: 15px;
}
